@import '~@eika/core-style';

.cookie-select {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .sgw-toggle-switch__button:disabled {
        cursor: not-allowed;
    }

    .sgw-toggle-switch {
        margin-right: $xtra-small;
        height: unset;
    }

    &:first-child {
        margin-top: $medium;
    }

    margin-bottom: $medium + $xtra-small;

    &:last-child {
        margin-bottom: $large + $small;
    }
}

.cookie-select__header {
    user-select: none;
    margin: 0;
}

.cookie-select__description {
    flex: 1 1 auto;
    user-select: none;
    width: 100%;
    margin: 0;
    margin-top: $xtra-small;
}