@import '~@eika/core-style';

.card-wrap {
    padding: $small;
}

#cookie-consent-modal-title {
    padding-top: $larger;
    margin-bottom: $larger;
}

.card-ingress {
    margin-bottom: $larger;

    h1 {
        font-size: var(--font-size--large);
    }

    h3 {
        font-size: var(--font-size--medium);
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: baseline;
            margin-top: $medium;

            &::before {
                margin-right: $xtra-small;
                display: inline-block;
                content: "";
                height: $smaller;
                width: $smaller;
                background-color: var(--theme-dark);
                border-radius: 50%;
            }
        }
    }
}
