@import '~@eika/core-style';

.cookie-consent-form .consent-title {
    margin-bottom: $medium;
}

.cookie-consent-form__submit {
    margin-top: auto;

    .cookie-consent-form__button-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        margin-top: auto;
        column-gap: $small;

        > * {
            margin-top: $medium;
        }

        .cookie-consent-form__button--tertiary {
            grid-column: 1/-1;
            align-self: center;
            display: block;
            flex: 1 1 100%;
            text-align: center;
        }


    }

    .success-card {
        display: flex;
        justify-content: center;
        align-items: center;

        & :last-child {
            margin-left: $medium;
        }
    }
}

.cookie-list {
    margin-bottom: $medium;

    .cookie-item {
        display: grid;
        grid-template-columns: auto 1fr;

        .cookie-select__description {
            grid-column: 2;
        }

    }
}
